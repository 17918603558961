import React from 'react'
import styled from 'styled-components'
import BasicButton from './BasicButton'
import {useSpring, animated} from 'react-spring'
import {Customer} from '../Models/CustomerInterface';

interface Props {
	customers: Customer[];
	witchListViewAppear:string;
	onClickJump: (e:string) => void;
	onClick: (e:string) => void;
}

const CustomerList = (props: Props) => {
	const customers = props.customers;	
	const otherInputs = customers.map(
		(customer:Customer, index:number) => {
			let othersItem;
			const others = customer.others.filter(other =>( !other.deleted_flag ))
			if (others.length !== 0){
				othersItem = others.map((other, index2) => { return ( <Td key={index2}>{other.others_name}/{other.amount}</Td> )});
			}
			return (
				<Tr key={index} onClick={() => props.onClickJump(customer.code)}>
					<Td>{customer.code}</Td>
					<Td>{customer.name}</Td>
					<Td>{customer.f_weight}</Td>
					<Td>{customer.i_weight}</Td>
					{othersItem}
				</Tr>
			);
		}
	);

	const ani = useSpring({
		opacity:1,
		from: {opacity: 0}
	});
	return ( 
		<Wrapper style={ani}  onClick={() => props.onClick('close')}>
			<ListDiv>
				<Table>
					<thead>
						<tr>
							<Th>会社コード</Th>
							<Th>会社名</Th>
							<Th>可燃</Th>
							<Th>不燃</Th>
						</tr>
					</thead>
					<tbody>
						{otherInputs}
					</tbody>
				</Table>
			</ListDiv>
			<ControllerDiv>
				<BasicButton value={"Close"}  onClick={() => props.onClick('close')}/>
				{props.witchListViewAppear === 'modifyList' &&
					<BasicButton value={"Send"}  onClick={() => props.onClick('send')}/>	
				}
			</ControllerDiv>
		</Wrapper>
	);
}

const Color1 = "#FFA07A";
const Color2 = "#000";

const Wrapper = styled(animated.div)`
	background-color: rgba( 0, 0, 0, 0.7 ); 
	position: absolute;
	height: 100vh;
	width:100%;
	top:0px; 
	left:0px; 
	overflow:auto;
	&::-webkit-scrollbar {
		display:none;
	}
`;

const ListDiv = styled.div`
	height: calc(100% - 60px);
	width:100%;
	overflow:auto;
	text-align:center;
	&::-webkit-scrollbar {
		display:none;
	}
`;

const Table = styled.table`
	border:"1";
`;

const Th = styled.th`	
	color: ${Color1};
	font-size: 1.7rem;
`;

const Tr = styled.tr`	
	
`;

const Td = styled.td`	
	border: solid 0px ${Color2};
	background: ${Color1};
	color: black;
	min-width:100px;
	font-size: 1.5rem;
	padding: 0.5rem 2rem;
	opacity:0.75;

`;

const ControllerDiv = styled.div`
	position: absolute;
	height:60px;
	width:100%;
	bottom:20px;
	left:0px; 
	overflow:auto;
	text-align: center;
	&::-webkit-scrollbar {
		display:none;
	}
`;

export default CustomerList