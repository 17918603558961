import React, {useState} from 'react'
import styled from 'styled-components'
import {useSpring, animated} from 'react-spring'

interface Props {
	onClick: () => void;
	value:string;
}

const RoundButton = (props: Props) => {
	const [state, toggle] = useState(true);
	const { x } = useSpring({ from: { x: 0 }, x: state ? 1 : 0, config: { duration: 200 } });
	function handleClick(){
		props.onClick();
		toggle(!state);
	};
	return (
		<Button 
			style={{
				transform: x.to({
					range: [0, 0.45,  1],
					output: [1, 1.1, 1]
				}).to(x => `scale(${x})`)
			}}
			onClick={handleClick}
		>
			{props.value}
		</Button>
		);
}

const Button = styled(animated.button)`
	font-size: 1.8rem;
	background: #ffc06e;
	color: #FFF;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
	border: none;
`;

export default RoundButton