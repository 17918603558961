import React from 'react'
import styled from 'styled-components'
import {useSpring, animated} from 'react-spring'
import CustomerInfo from './CustomerInfo'
import NumForm from './NumForm'
import RoundButton from './RoundButton'
import OtherForm from './OtherForm'
import {Customer} from '../Models/CustomerInterface';

interface Props {
	onChange: (e:string) => void;
	onClickOtherAdd: () => void;
	onClickOtherDelete: (otherKey: number) => void;
	customer: Customer;
	inputRef: React.RefObject<HTMLInputElement>;
}

const Board = (props: Props) => {
	
	// ここでフィルターをかけると削除した後にindexがずれるため、分岐で対応
	const others = props.customer.others;
	const others_length = props.customer.others.filter(other =>( !other.deleted_flag )).length;

	const otherInputs = others.map(
		(otherInput, index) => {
			if(!otherInput.deleted_flag)
			{
				return (
					<li key={index}>
						<OtherForm otherKey={index} name={otherInput.others_name} amount={otherInput.amount} onChange={(e:string) => props.onChange(e)} onClickDelete ={(otherKey:number) => props.onClickOtherDelete(otherKey)}/>
					</li>
				);
			}
		}
	);

	const ani = useSpring({
		height: 250 + others_length * 60,
		from: {height: 0}
	});

	return ( 
		<Wrapper style={ ani }>
			<CustomerInfo customer={props.customer}/>
			<NumForm label={"可燃"} name={'f_weight'} value={props.customer.f_weight} onChange={(e:string) => props.onChange(e)} inputRef = {props.inputRef} footLabel = { "[" + props.customer.f_unit_name + "]"}/>
			<NumForm label={"不燃"} name={'i_weight'} value={props.customer.i_weight} onChange={(e:string) => props.onChange(e)} inputRef = {props.inputRef} footLabel = { "[" + props.customer.i_unit_name + "]"}/>
			<Ol>{otherInputs}</Ol>
			<RoundButton value={"＋"} onClick={() => props.onClickOtherAdd()} />
		</Wrapper>
	);
}
const Wrapper = styled(animated.div)`
	font-size: 2rem;
	margin-top: 2%;
	margin-left: 5%;
	margin-right: 5%;
	margin-bottom: 5%;
	padding:1rem;
	width: 90%;	
	min-width: 500px;
	border-radius: 1rem;
	text-align:center;
    color: #432E15;
    background: linear-gradient(to bottom, #FFE6C7, #FFA07A);
    border-top: solid 1.5rem #ffc06e;
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.32);
`;

const Ol = styled.ol`	
	margin:0rem;
	padding: 0rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	list-style-type: none;
`;

export default Board