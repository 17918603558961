import React from 'react'
import styled from 'styled-components'
import BasicButton from './BasicButton'


interface Props {
	onClick: (s:string) => void;
}

const Foot = (props: Props) => {
	return ( 
		<>
			<Right>
				<BasicButton value={"会社一覧"} onClick={() => props.onClick('customerList')}/>
				　
				<BasicButton value={"送信予定一覧"} onClick={() => props.onClick('modifyList')}/>
			</Right>
			<Left>
				<NoteTitle>キーボード操作</NoteTitle>
				<NoteSentence>左右キーで得意先を切り替えます。<br/>Enterキーで入力モードになります。<br/>Tabキーで次の欄にフォーカスします。<br/>再びEnterキーで左右キーが効くようになります。</NoteSentence>
				<NoteTitle>送信</NoteTitle>
				<NoteSentence>「送信予定一覧」→「Send」<br/>一度送信した得意先はこの入力中は表示されなくなります。</NoteSentence>
				<NoteTitle>仕様</NoteTitle>
				<NoteSentence>その他の項目の金額を0円で送信すると、請求書に表示されません。<br/>その他の名称の先頭に内を入力して送信すると、金額が0円でも請求書に表示されます。<br/>その他の名称に{'<br>'}を入力すると、請求書に表示される時に改行されます。</NoteSentence>
			</Left> 
		</>
	);
}

const NoteTitle = styled.p`
	color: #faab43;
`;

const NoteSentence = styled.p`
	color: #5e4a2f;
`;

const Left = styled.div`
	padding-left: 10%;
	float: left;
`;
const Right = styled.div`
	padding-right: 10%;
	float: right;
`;

export default Foot