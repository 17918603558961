import React from 'react'
import styled from 'styled-components'
import RoundButton from './RoundButton'
import Board from './Board'
import {Customer} from '../Models/CustomerInterface';

interface Props {
	customer:Customer;
	selectedIndex: number;
	inputRef: React.RefObject<HTMLInputElement>;
	onClick: (b:boolean) => void;
	onChange: (e:any) => void;
	onClickOtherAdd: () => void;
	onClickOtherDelete: (otherKey: number)=> void;
}

const Body = (props: Props) => {
	return ( 
		<Wrpper>
			<WrapButton style={left} >
				<RoundButton value={"＜"} onClick={() => props.onClick(true)}/>
			</WrapButton>
			<Board 
				customer={props.customer} 
				// selectedIndex={ props.selectedIndex } 
				onChange={(e:any) => props.onChange(e)} 
				onClickOtherAdd={() => props.onClickOtherAdd()} 
				onClickOtherDelete = {(otherKey:number) => props.onClickOtherDelete(otherKey)}
				inputRef = {props.inputRef}
			/>
			<WrapButton style={right}>
				<RoundButton value={"＞"} onClick={() => props.onClick(false)}/>
			</WrapButton>
		</Wrpper>
	);
}
const WrapButton = styled.div`
	position: fixed;
	top:50%;
	height: 50vh;
	margin-left: 5px;
	margin-right: 5px;
	margin-top:-5%;
`;

const right: React.CSSProperties = {
	right:0,
};
const left: React.CSSProperties = {
	left:0,
};
  

const Wrpper = styled.div`
	display: flex;
`;



export default Body