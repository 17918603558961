import React from 'react'
import styled from 'styled-components'
import Label from './Label'
import { Customer } from '../Models/CustomerInterface';

interface Props {
	customer: Customer | null | undefined
}

const CustomerInfo = (props: Props) => {
	let code = '';
	let name = '';
	if (props.customer !== null && props.customer !== undefined){
		code = props.customer.code;
		name = props.customer.name;
	} 

	return (
	<Wrapper>
		<Label value={code} />
		<Label value={name} />
	</Wrapper>
	);
}

const Wrapper = styled.div`
	text-align: center;
	width: 500px;
	margin:0 auto;
	background: #FFF2E3;
	color: #432E15;
`;

export default CustomerInfo