import React from 'react'
import styled from 'styled-components'

interface Props {
	value: string;
	onClick: () => void;
}

const BasicButton  = (props: Props) => {
	return <Button onClick={props.onClick}>{props.value}</Button>;
}

const Color1 = "#FFA07A";
const Color2 = "#000";

const Button = styled.button`
	display: inline-block;
	background: ${Color1} ;
	color:${Color2};
	border:none;
	position:relative;
	height:40px;
	font-size:1.3em;
	padding:0 2em;
	margin: 0.5rem;
	cursor:pointer;
	transition:800ms ease all;
	outline:none;

	&:hover {	
		transform: translateY(2px);
		transition: 0.5s;
		background: ${Color1};
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);
	}
	&:hover{
		background:${Color2};
		color:${Color1};
	}
	&:before,&:after{
		content:'';
		position:absolute;
		top:0;
		right:0;
		height:2px;
		width:0;
		background: ${Color1};
		transition:200ms ease all;
	}
	&:after{
		right:inherit;
		top:inherit;
		left:0;
		bottom:0;
	}
	&:hover:before,&:hover:after{
		width:100%;
		transition:600ms ease all;
	}
`;


export default BasicButton
